<template>
	<!-- <Upload
        class="dragFiles"
        :multiple="multiple"
        type="drag"
        ref="upload"
        :before-upload="beforeDrag"
        :action="''"
        :data="{token}"
    > -->
	<div class="dragFiles" ref="dragFilesDiv">
		<el-upload
			:multiple="multiple"
			v-if="!disabled"
			ref="upload"
			:show-file-list="uploading"
			:on-success="handleSuccess"
			:before-upload="beforeUload"
			:action="baseUrl + '/api/file'"
			:data="{ token }"
		>
			<div class="upload-div">
				<i class="iconfont iconjijia_shangchuan" style="font-size: 24px; display: block; margin-top: 20px"></i>
				<p style="font-size: 14px; font-weight: 400">
					<span style="color: #202126">拖拽上传</span>
					<span style="color: #8a8f99; margin: 0 8px">或</span>
					<span class="public-color">点击上传</span>
				</p>
			</div>
		</el-upload>
		<!-- 上传线框 -->
		<el-upload
			v-if="!disabled && uploadBorderShow"
			:multiple="multiple"
			type="drag"
			ref="upload"
			:show-file-list="uploading"
			:on-success="handleSuccess"
			:before-upload="beforeUload"
			:action="baseUrl + '/api/file'"
			:data="{ token }"
		>
			<div class="upload-border-line" @click="uploadBorderShow = false">
				<p style="font-size: 20px; font-weight: 500; color: #bdc0c7">将文件拖拽至框内上传</p>
			</div>
		</el-upload>
		<div class="demo-upload-list" v-for="(item, index) in imageArr" style="margin-top: 24px" :key="index">
			<template>
				<img
					:src="item.filepath.startsWith('http') ? item.filepath : baseUrl + '/' + item.filepath"
					@click.stop="handleView(index)"
				/>
				<div class="del-image" v-show="!disabled">
					<Icon type="md-close" :size="18" color="#fff" @click.stop="handleRemoveImg(index)"></Icon>
				</div>
			</template>
		</div>
		<div v-loading="uploadLoading" style="margin-bottom: 10px; margin-top: 24px" v-if="fileArr && fileArr.length != 0">
			<div v-for="(item, index) in fileArr" :key="index">
				<div class="flie-container">
					<p><i class="iconfont iconbim_fujian"></i></p>
					<div class="file-name">
						<p class="name">{{ item.filename.split('.')[0].substring(0, item.filename.split('.')[0].length - 1) }}</p>
						<span
							>{{ item.filename.split('.')[0][item.filename.split('.')[0].length - 1] }}.{{
								item.filename.split('.').length > 1 ? item.filename.split('.')[1] : ''
							}}</span
						>
					</div>
					<p class="file-option">
						<span class="file-size">{{ formtSzie(item.filesize) }}</span>
						<span class="file-btn">
							<span @click="openFile(item)">预览</span>
							&nbsp;
							<span
								v-if="!disabled ? false : true"
								style="color: #087aff"
								@click="
									downloadFile(
										item.filepath.indexOf('http') > -1 ? item.filepath : baseUrl + '/' + item.filepath,
										item.filename
									)
								"
							>
								{{ $t('appQrcode.Index.601905-3') }}
							</span>
							<!-- <a :href="baseUrl + '/' + item.filepath" :download="item.filename" v-if="!disabled? false:true">下载</a> -->
						</span>
					</p>
				</div>
			</div>
		</div>
		<span v-if="disabled && extra_file && extra_file.length == 0">{{ showNone ? '无' : '' }}</span>
	</div>
</template>

<script>
import { getToken } from '@/utils/tools';
import { Upload } from 'element-ui';
import { format } from 'date-fns';
import { downloadFileBlob, openUrl } from '@/utils/tools';
const pdf = '//static.bimcc.com/images/disk/pdf.png';
const ppt = '//static.bimcc.com/images/disk/ppt.png';
const word = '//static.bimcc.com/images/disk/word.png';
const excel = '//static.bimcc.com/images/disk/excel.png';
const none = '//static.bimcc.com/images/disk/none.png';
export default {
	components: {
		'el-upload': Upload
	},
	props: {
		files: {
			type: Array,
			default() {
				return [];
			}
		},
		btnName: {
			type: String,
			default: '上传文件'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: 'default'
		},
		icon: {
			type: String,
			default: 'ios-cloud-upload-outline'
		},
		index: {
			type: [Number, String],
			default: 0
		},
		// 是否显示border
		isBorder: {
			type: Boolean,
			default: true
		},
		multiple: {
			type: Boolean,
			default: false
		},
		// 允许上传的文件格式
		fileTypeList: {
			type: Array,
			default: () => []
		},
		downloadUrl: {
			type: String,
			default: ''
		},
		// 展示底部操作按钮
		showFooter: {
			type: Boolean,
			default: true
		},
		// 展示标题
		showHeader: {
			type: Boolean,
			default: true
		},
		showNone: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		files: {
			handler(files) {
				this.imageArr = [];
				this.fileArr = [];
				files.map((item) => {
					if (typeof item === 'object') {
						if (
							this.imageType.indexOf(item.filepath.substring(item.filepath.lastIndexOf('.') + 1).toLowerCase()) > -1
						) {
							this.imageArr.push(item);
						} else {
							this.fileArr.push(item);
						}
					} else {
						this.imageArr.push({
							filename: '',
							user_name: '未知',
							created_at: format(new Date(), 'yyyy/MM/dd-HH:mm'),
							filepath: item
						});
					}
				});
				this.extra_file = files;
			},
			immediate: true
		},
		extra_file(data) {
			this.loading = false;
			// this.$refs.upload.fileList = [];
			this.$emit('extra-files', data, this.index);
		}
	},
	data() {
		return {
			format: format,
			imageType: ['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'],
			imageArr: [],
			fileArr: [],
			uploading: false,
			token: getToken(),
			baseUrl: process.env.VUE_APP_BASE_URL,
			currentUser:
				JSON.parse(localStorage.getItem('userName')) + '-' + JSON.parse(localStorage.getItem('thisRole')).name,
			extra_file: [],
			uploadBorderShow: false,
			uploadLoading: false
		};
	},
	methods: {
		formtSzie(v) {
			const base = 1024;
			const TS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
			let tindex = 0;
			if (v < base) {
				return `${v}${TS[tindex]}`;
			} else {
				let res = '';
				const handler = (value) => {
					const s = value / 1024;
					tindex += 1;
					if (s >= base && tindex < TS.length) {
						handler(s);
					} else {
						res = `${Number(s.toFixed(2))}${TS[tindex]}`;
					}
				};
				handler(v);
				return res;
			}
		},
		beforeDrag() {
			console.log('文件移入********');
		},
		cancelDrag() {
			console.log('取消');
			this.uploadBorderShow = false;
		},
		headerCellStyle({ column, columnIndex }) {
			console.log(column, columnIndex);
			return {
				color: this.$props.isBorder ? '#25282E' : '#7D818A'
			};
		},
		openFile(row) {
			console.log();
			if (
				['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].indexOf(
					row.filepath.substring(row.filepath.lastIndexOf('.') + 1).toLowerCase()
				) >= 0
			) {
				this.$store.commit('setImageUrls', { data: [this.baseUrl + '/' + row.filepath] });
			} else if (
				['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xlsx', 'xls'].indexOf(
					row.filepath.substring(row.filepath.lastIndexOf('.') + 1).toLowerCase()
				) >= 0
			) {
				let url = 'https://weboffice.bimcc.net?furl=';
				if (this.baseUrl.includes('https://')) {
					url = 'https://weboffice.bimcc.net?ssl=1&furl=';
				}
				openUrl(url + this.baseUrl + '/' + row.filepath, row.filepath);
				// window.open(url + this.baseUrl + '/' + row.filepath)
			} else {
				this.$Message.info('无法打开此格式的文件！');
			}
		},
		fileType(url) {
			if (url.indexOf('pdf') > -1) return pdf;
			else if (url.indexOf('ppt') > -1) return ppt;
			else if (url.indexOf('doc') > -1) return word;
			else if (url.indexOf('xls') > -1) return excel;
			else return none;
		},
		beforeUload(file) {
			// console.log('beforeUload')
			this.uploading = true;
			if (this.fileTypeList.length) {
				let arr = [];
				this.fileTypeList.forEach((item) => {
					if (file.name.indexOf(item) > -1) {
						arr.push(file.name);
					}
				});
				if (arr.length == 0) {
					this.$Message.error('请上传 ' + this.fileTypeList.join(',') + ' 格式的文件！');
					return false;
				}
			}
		},
		handleRemoveImg(index) {
			this.imageArr.splice(index, 1);
			this.extra_file = [].concat(this.imageArr, this.fileArr);
		},
		handleRemove(index) {
			this.fileArr.splice(index, 1);
			this.extra_file = [].concat(this.imageArr, this.fileArr);
		},
		handleSuccess(res, file, fileList) {
			console.log(fileList);
			this.uploading = false;
			this.uploadBorderShow = false;
			this.$emit('uploading', res, file);
			if (this.extra_file == null) {
				this.extra_file = new Array();
			}
			//let path = res.data.filepath;
			//判断上传文件是否为图片
			if (this.imageType.indexOf(res.data.fileext.toLowerCase()) > -1) {
				// path=res.data.thumb_path;
			}
			this.extra_file.push(res.data);
			// this.extra_file.push({
			//     id: res.data.id,
			//     filename: res.data.filename,
			//     user_name:res.data.user_name,
			//     created_at:format(res.data.created_at, 'yyyy/MM/dd-HH:mm'),
			//     filepath: path,
			//     role_name: res.data.role_name,
			//     fileext: res.data.fileext,
			//     filesize: res.data.filesize
			// });
		},
		handleView(index) {
			let arr = [];
			this.imageArr.map((item) => {
				arr.push(this.baseUrl + '/' + item.filepath);
			});
			this.$store.commit('setImageUrls', { data: arr, index: index });
		},
		/**
		 * @description 文件下载
		 * @param {String, String} path, name
		 */
		async downloadFile(url, name) {
			this.uploadLoading = true;
			await downloadFileBlob(url, name);
			this.uploadLoading = false;
		}
	},
	mounted() {
		var dropEle = this.$refs.dragFilesDiv;
		let that = this;
		dropEle.addEventListener(
			'dragenter',
			function (e) {
				// console.log('拖拽进了文件')
				if (!that.uploadBorderShow) {
					that.uploadBorderShow = true;
				}
				e.preventDefault();
				e.stopPropagation();
			},
			false
		);
		dropEle.addEventListener(
			'dragover',
			function (e) {
				e.preventDefault();
				e.stopPropagation();
				// that.uploadBorderShow=true
			},
			false
		);

		dropEle.addEventListener(
			'dragleave',
			function (e) {
				// console.log('拖拽离开了')
				e.preventDefault();
				e.stopPropagation();
				// that.uploadBorderShow=false
			},
			false
		);

		dropEle.addEventListener(
			'drop',
			function (e) {
				e.preventDefault();
				e.stopPropagation();
				// var df = e.dataTransfer;
				// console.log(df.items ,'items ')
				// 处理拖拽文件的逻辑
			},
			false
		);
		// console.log(this.downloadUrl,'downloadUrl')
	}
};
</script>

<style scoped lang="less">
.dragFiles {
	position: relative;
	height: 100%;
	.upload-border-line {
		height: 100%;
		width: 100%;
		position: absolute;
		background: rgba(255, 255, 255, 0.9);
		border: 2px dashed #cc4429;
		border-radius: 14px;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 20;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.public-color {
	//color: @activeCol!important;
	cursor: default;
}
.upload-div {
	// margin-left: 18px;
	width: 336px;
	height: 120px;
	background: #ffffff;
	border: 1px dashed #d7dae0;
	border-radius: 7px;
	text-align: center;
}
.upload-title {
	line-height: 40px;
	display: flex;
	justify-content: space-between;
	.colse-btn {
		width: 16px;
		height: 16px;
		background: #bdc0c7;
		text-align: center;
		line-height: 16px;
		color: #fff;
		margin-top: 10px;
	}
}

.file-name {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: ~'calc(100% - 23px)';
	vertical-align: middle;
}
.demo-upload-list {
	display: inline-block;
	width: 100px;
	height: 100px;
	text-align: center;
	line-height: 100px;
	border: 1px solid transparent;
	border-radius: 4px;
	overflow: hidden;
	background: #fff;
	position: relative;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	margin-right: 4px;
}
.flie-container {
	display: flex;
	.file-name {
		display: flex;
		padding-left: 10px;
		.name {
			max-width: 120px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.file-option {
		width: 90px;
		text-align: right;
		.file-size {
			display: inline;
		}
		.file-btn {
			display: none;
			cursor: pointer;
		}
		&:hover {
			.file-size {
				display: none;
			}
			.file-btn {
				display: inline;
			}
		}
	}
	&:hover {
		color: rgba(0, 129, 153, 1);
	}
}
.demo-upload-list img {
	width: 100%;
	height: 100%;
	cursor: pointer;
}
.del-image {
	position: absolute;
	top: 0;
	width: 18px;
	right: 0;
	background: #d3d4d5;
	color: #fff;
	line-height: 16px;
}
</style>
<style lang="less">
.dragFiles {
	.ivu-btn {
		height: 30px !important;
	}
	.ivu-btn-primary {
		//background: @activeCol!important ;
		// border-color: @activeCol!important ;
	}
	.ivu-upload-drag {
		border: unset !important;
	}
	.ivu-upload-drag {
		position: unset !important;
	}
}

.operation-down {
	padding: 0 5px;
	color: var(--themeColor);
	cursor: pointer;
}
</style>
